import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  headingStyles,
} from '../styles';
import { Container, Heading, HtmlContent, Button } from './ui';

const StyledImageContentBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 15px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 25px;
  `}
`;

const StyledItems = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledItem = styled.div`
  background-color: ${({ backgroundColour }) =>
    backgroundColour === 'Dark Green'
      ? brandColours.primary
      : backgroundColour === 'Dark Orange'
      ? brandColours.secondary
      : backgroundColour === 'Pink'
      ? brandColours.senary
      : backgroundColour === 'Green'
      ? brandColours.quaternary
      : backgroundColour === 'Orange'
      ? brandColours.tertiary
      : `unset`};

  color: ${({ textColour }) =>
    textColour === 'Dark Green'
      ? brandColours.primary
      : textColour === 'Beige'
      ? brandColours.septenary
      : `unset`};

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${minBreakpointQuery.smedium`
    height: 100%;
  `}

  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-row: 1;
          grid-column: 2;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  padding: 30px;

  ${minBreakpointQuery.large`
    padding: 35px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 40px;
  `}
`;

const StyledSubheading = styled.h3`
  ${headingStyles()};
`;

const StyledText = styled(HtmlContent)`
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const ImageContentBlocks = ({ heading, items }) => (
  <StyledImageContentBlocks>
    <Container wide={true}>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(
          ({ image, heading, text, link, backgroundColour, textColour }, i) => (
            <StyledItem
              key={i}
              backgroundColour={backgroundColour}
              textColour={textColour}
            >
              <StyledImage
                image={image.gatsbyImageData}
                alt={image.alt}
                flip={i % 2}
              />
              <StyledContent>
                <StyledSubheading>{heading}</StyledSubheading>
                {text && <StyledText html={text} />}
                {link && (
                  <StyledButton to={link.pageUrl}>{link.text}</StyledButton>
                )}
              </StyledContent>
            </StyledItem>
          )
        )}
      </StyledItems>
    </Container>
  </StyledImageContentBlocks>
);

export default ImageContentBlocks;
