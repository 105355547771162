import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading } from './ui';
import TeamCard from './TeamCard';

const StyledTeamListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const TeamListing = ({ heading, items }) => {
  const {
    allDatoCmsTeam: { nodes },
  } = useStaticQuery(graphql`
    query TeamListingQuery {
      allDatoCmsTeam(sort: { order: ASC, fields: position }) {
        nodes {
          ...TeamCardFragment
        }
      }
    }
  `);

  const team = items.length > 0 ? items : nodes;

  return (
    team.length > 0 && (
      <StyledTeamListing>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledItems>
            {team.map(({ id, image, name, role, bio }) => (
              <TeamCard
                key={id}
                image={image}
                name={name}
                role={role}
                bio={bio}
              />
            ))}
          </StyledItems>
        </Container>
      </StyledTeamListing>
    )
  );
};

export default TeamListing;
