import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fontSize,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, HtmlContent, Slider } from './ui';

const StyledTeamTestimonials = styled.section`
  ${sectionMargins()};
  overflow-x: hidden;
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 8px;

  ${minBreakpointQuery.small`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}
`;

const StyledItems = styled(Slider)`
  .slick-arrow {
    top: 50%;

    ${minBreakpointQuery.small`
      height: 60px;
      width: 60px;
    `}

    &.slick-prev {
      left: 0;
      transform: translate(-25px, -50%);

      ${minBreakpointQuery.xlarge`
        transform: translate(-50%, -50%);
      `}
    }

    &.slick-next {
      right: 0;
      transform: translate(25px, -50%);

      ${minBreakpointQuery.xlarge`
        transform: translate(calc(50% + 10px), -50%);
      `}
    }
  }

  .slick-list {
    margin: 0 -10px;

    ${minBreakpointQuery.xlarge`
      overflow: visible;
      clip-path: inset(0 -100vw 0 0);
    `}
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 10px;
    height: inherit;

    > div {
      height: 100%;
    }
  }
`;

const StyledItem = styled.article`
  padding: 16px;
  height: 100%;
  border: solid 1px ${brandColours.primary};
  border-radius: 4px;

  ${minBreakpointQuery.small`
    padding: 24px;
  `}

  ${minBreakpointQuery.large`
    padding: 32px;
  `}
`;

const StyledItemInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;

  ${minBreakpointQuery.small`
    gap: 20px;
  `}

  ${minBreakpointQuery.large`
    gap: 24px;
  `}
`;

const StyledQuote = styled.blockquote`
  margin: 0;
`;

const StyledQuoteText = styled.p`
  ${fontSize(15)};
  line-height: 1.7;

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}
`;

const StyledPerson = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;

  ${minBreakpointQuery.small`
    gap: 14px;
  `}

  ${minBreakpointQuery.large`
    gap: 18px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
`;

const StyledPersonText = styled.p`
  ${fontSize(13)};

  ${minBreakpointQuery.small`
    ${fontSize(14)};
  `}

  strong {
    display: block;
    margin-bottom: 4px;
    ${fontSize(14)};

    ${minBreakpointQuery.small`
      ${fontSize(15)};
    `}
  }
`;

const TeamTestimonials = ({ heading, text, testimonials }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: breakpointSizes.medium - 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpointSizes.tsmall - 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledTeamTestimonials>
      <Container>
        <StyledHeader>
          <Heading>{heading}</Heading>
          {text && <StyledText html={text} />}
        </StyledHeader>
        <StyledItems sliderOptions={sliderOptions}>
          {testimonials.map(({ testimonial, image, name, role }, id) => (
            <StyledItem key={id}>
              <StyledItemInner>
                {testimonial && (
                  <StyledQuote>
                    <StyledQuoteText>{testimonial}</StyledQuoteText>
                  </StyledQuote>
                )}
                <StyledPerson>
                  <StyledImage image={image.gatsbyImageData} alt={image.alt} />
                  <StyledPersonText>
                    <strong>{name}</strong>
                    {role}
                  </StyledPersonText>
                </StyledPerson>
              </StyledItemInner>
            </StyledItem>
          ))}
        </StyledItems>
      </Container>
    </StyledTeamTestimonials>
  );
};

export default TeamTestimonials;
