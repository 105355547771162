import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, fontSize } from '../styles';

const StyledTeamCard = styled.article``;

const StyledImage = styled(GatsbyImage)`
  border-radius: 10px;
`;

const StyledName = styled.h3`
  margin-top: 15px;
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledRole = styled.p`
  margin-top: 10px;
`;

const StyledBio = styled.p`
  margin-top: 10px;
  ${fontSize(14)};
  line-height: 1.5;
`;

const TeamCard = ({ image, name, role, bio }) => (
  <StyledTeamCard>
    <StyledImage image={image.gatsbyImageData} alt={image.alt} />
    <StyledName>{name}</StyledName>
    <StyledRole>{role}</StyledRole>
    {bio && <StyledBio>{bio}</StyledBio>}
  </StyledTeamCard>
);

export default TeamCard;

export const TeamCardFragment = graphql`
  fragment TeamCardFragment on DatoCmsTeam {
    id
    image {
      gatsbyImageData(width: 460, height: 520)
      alt
    }
    name
    role
    bio
  }
`;
