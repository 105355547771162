import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledUspCards = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 8px;

  ${minBreakpointQuery.small`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}
`;

const StyledItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(6, 1fr);
  `}
`;

const StyledItem = styled.li`
  background-color: ${({ backgroundColour }) =>
    backgroundColour === 'Dark Green'
      ? brandColours.primary
      : backgroundColour === 'Dark Orange'
      ? brandColours.secondary
      : backgroundColour === 'Pink'
      ? brandColours.senary
      : backgroundColour === 'Green'
      ? brandColours.octonary
      : backgroundColour === 'Orange'
      ? brandColours.tertiary
      : `unset`};
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};
  color: ${brandColours.septenary};
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;

  ${minBreakpointQuery.small`
    ${fontSize(16)};  
  `}
`;

const UspCards = ({ heading, text, items }) => (
  <StyledUspCards>
    <Container>
      <StyledHeader>
        <Heading>{heading}</Heading>
        {text && <StyledText html={text} />}
      </StyledHeader>
      <StyledItems>
        {items.map(({ text, backgroundColour }, id) => (
          <StyledItem key={id} backgroundColour={backgroundColour}>
            {text}
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledUspCards>
);

export default UspCards;
