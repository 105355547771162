import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { BlobBackground, Button, Container, HtmlContent } from './ui';

const StyledContent = styled.section`
  ${({ reduceGaps }) =>
    reduceGaps ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const StyledInner = styled.div`
  ${({ twoColumns }) => {
    if (twoColumns) {
      return css`
        ${minBreakpointQuery.mlarge`
          column-count: 2;
          column-gap: 60px;
        `}

        ${minBreakpointQuery.large`
          column-gap: 80px;
        `}

        ${minBreakpointQuery.xxlarge`
          column-gap: 100px;
        `}
      `;
    }
  }};
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

const Content = ({
  html,
  link,
  twoColumns,
  widerContainer,
  backgroundColour,
  reduceGaps,
}) => (
  <StyledContent reduceGaps={reduceGaps}>
    <BlobBackground colour={backgroundColour}>
      <Container narrow={!widerContainer && true}>
        <StyledInner twoColumns={twoColumns}>
          <HtmlContent html={html} />
        </StyledInner>
        {link && (
          <StyledButton
            {...(link.page
              ? {
                  to: link.page,
                }
              : {
                  href: link.url,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                })}
          >
            {link.text}
          </StyledButton>
        )}
      </Container>
    </BlobBackground>
  </StyledContent>
);

export default Content;
