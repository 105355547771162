import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles';
import {
  Svg,
  Container,
  Heading,
  HtmlContent,
  Link,
  Button,
  Video,
} from './ui';
import imageBlobMask from '../images/image-blob-mask.svg';
import blobShape from '../images/blob-shape.inline.svg';

const StyledMediaContent = styled.section`
  ${({ hasBlob }) => {
    if (hasBlob) {
      return css`
        ${sectionPaddings()};
        overflow: hidden;
      `;
    } else {
      return css`
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledOuter = styled.div``;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: ${({ isVideo, imageBlobMask, flip }) =>
      isVideo
        ? 'repeat(2, 1fr)'
        : imageBlobMask && flip
        ? '1fr 1.2fr'
        : imageBlobMask
        ? '1.2fr 1fr'
        : flip
        ? '1.2fr 1fr'
        : '1fr 1.2fr'};
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${({ isVideo }) => {
    if (!isVideo) {
      return css`
        ${minBreakpointQuery.mlarge`
          gap: 80px;
        `}

        ${minBreakpointQuery.large`
          gap: 100px;
        `}
      `;
    }
  }}
`;

const StyledImageWrapper = styled.div`
  position: relative;

  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-row: 1;
          grid-column: 2;
        `}
      `;
    }
  }}
`;

const StyledBlobShape = styled(Svg)`
  position: absolute;
  top: 50%;
  display: block;
  height: calc(100% + 40px);

  ${({ colour }) => {
    if (colour) {
      return css`
        fill: ${colour};
      `;
    }
  }}

  ${minBreakpointQuery.small`
    height: calc(100% + 60px);
  `}

  ${minBreakpointQuery.smedium`
    height: calc(100vw / 2);
    max-height: 620px;
  `}

  ${({ flip }) => {
    if (flip) {
      return css`
        right: 0;
        transform: translate(50%, -50%);

        ${minBreakpointQuery.large`
          transform: translate(60%, -50%);
        `}
      `;
    } else {
      return css`
        left: 0;
        transform: translate(-50%, -50%);

        ${minBreakpointQuery.large`
          transform: translate(-60%, -50%);
        `}
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;

  ${({ blobMask }) => {
    if (blobMask) {
      return css`
        margin: 0 auto;
        max-width: 700px;
        aspect-ratio: 5/4;
        mask-image: url('${imageBlobMask}');
        mask-repeat: no-repeat;
        mask-position: 50% 50%;
        mask-size: contain;
      `;
    } else {
      return css`
        ${maxBreakpointQuery.smedium`
          aspect-ratio: 3/2;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  position: relative;
`;

const StyledText = styled(HtmlContent)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const StyledFeaturedPoints = styled.ul``;

const StyledFeaturedPoint = styled.li`
  padding: 20px 0;
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 1.4;
  border-bottom: 1px solid ${brandColours.primary};

  ${({ colour }) => {
    if (colour) {
      return css`
        color: ${colour};
      `;
    }
  }}

  ${minBreakpointQuery.small`
    padding-top: 22px;
    padding-bottom: 22px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    padding-top: 25px;
    padding-bottom: 25px;
    ${fontSize(25)};
  `}
`;

const StyledFeaturedLinks = styled.div``;

const StyledFeaturedLink = styled(Link)`
  position: relative;
  display: block;
  padding: 20px 55px 20px 0;
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 1.4;
  border-bottom: 1px solid ${brandColours.primary};
  transition: ${standardTransition('color')};

  ${minBreakpointQuery.small`
    padding-top: 26px;
    padding-right: 60px;
    padding-bottom: 26px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    padding-top: 32px;
    padding-right: 65px;
    padding-bottom: 32px;
    ${fontSize(25)};
  `}

  &:after {
    content: '→';
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
    height: 40px;
    width: 40px;
    color: ${standardColours.white};
    background-color: ${brandColours.primary};
    border-radius: 50%;
    transform: translateY(-50%);
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.small`
      padding-bottom: 4px;
      height: 45px;
      width: 45px;
    `}

    ${minBreakpointQuery.large`
      padding-bottom: 5px;
      height: 50px;
      width: 50px;
    `}
  }

  &:hover {
    color: ${brandColours.secondary};

    &:after {
      background-color: ${brandColours.secondary};
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const MediaContent = ({
  image,
  video,
  heading,
  text,
  featuredPoints,
  featuredLinks,
  link,
  imageBlobMask,
  blobColour,
  flip,
}) => (
  <StyledMediaContent hasBlob={blobColour}>
    <StyledOuter>
      <Container>
        <StyledInner isVideo={video} imageBlobMask={imageBlobMask} flip={flip}>
          <StyledImageWrapper flip={flip}>
            {blobColour && (
              <StyledBlobShape
                image={blobShape}
                colour={blobColour}
                flip={flip}
              />
            )}
            {image ? (
              <StyledImage
                image={image.gatsbyImageData}
                alt={image.alt}
                blobMask={imageBlobMask}
              />
            ) : (
              <Video data={video} />
            )}
          </StyledImageWrapper>
          <StyledContent>
            <Heading>{heading}</Heading>
            {text && <StyledText html={text} />}
            {featuredPoints.length > 0 && (
              <StyledFeaturedPoints>
                {featuredPoints.map(({ id, text, colour }) => (
                  <StyledFeaturedPoint key={id} colour={colour && colour.hex}>
                    {text}
                  </StyledFeaturedPoint>
                ))}
              </StyledFeaturedPoints>
            )}
            {featuredLinks.length > 0 && (
              <StyledFeaturedLinks>
                {featuredLinks.map(({ id, text, page }) => (
                  <StyledFeaturedLink key={id} to={page}>
                    {text}
                  </StyledFeaturedLink>
                ))}
              </StyledFeaturedLinks>
            )}
            {link && (
              <StyledButton
                {...(link.page
                  ? {
                      to: link.page,
                    }
                  : {
                      href: link.url,
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
              >
                {link.text}
              </StyledButton>
            )}
          </StyledContent>
        </StyledInner>
      </Container>
    </StyledOuter>
  </StyledMediaContent>
);

export default MediaContent;
